import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Navbar from "../admin-navbar/Navbar";
import * as api from "../../../api/ApiRequest";
import NotificationContainer from "../../utility/NotificationContainer";
import { showNotification } from "../../utility/showNotification";
import { getFromStorage } from "../../utility/Storage";
import Select from "react-select";
import moment from "moment-timezone"
import "./adminnewsedit.css"

export default function AdminNewsEdit() {
    const { id } = useParams();
	const navigate = useNavigate()
    const { state: searchedDate } = useLocation()

	const [newsDetails, setNewsDetails] = useState({
        title: "",
        desc: "",
        source: "",
        link: "",
        publish_date: "",
        publish_time: "",
    });
    const [sourceState, setSourceState] = useState("");
    const [sources, setSources] = useState([])

    const MAX_TITLE_COUNT = 100
    const MAX_DESCRIPTION_COUNT = 1000

	useEffect(() => {
        fetchData()
    }, 
    // eslint-disable-next-line
    [])
    
    const fetchData = async () => {
		const res = await api.fetchNewsListById(id);
        setNewsDetails({
			title: res.news[0].news_title,
			desc: res.news[0].news_details,
			source: res.news[0].source_id,
			link: res.news[0]?.news_link,
            publish_date: moment.unix(res.news[0]?.publish_date).format('YYYY-MM-DD'),
            publish_time: moment.unix(res.news[0]?.publish_time).format('YYYY-MM-DDTHH:mm'),
		})

		setSourceState({
			value: res.news[0].source_id,
			label: res.news[0].source
		})

		const medias = await api.fetchForeignMediaNames()
        
        const mediaList = medias?.list?.map(each => {
            return {
                value: each.id,
                label: each.media_name
            }
        })

        setSources(mediaList)
    }

    const badgeColorTitle = () => {
        if (newsDetails.title.length < (MAX_TITLE_COUNT - 50)) {
            return "newsform__title-badge-color";
        } else if (
            newsDetails.title.length >= (MAX_TITLE_COUNT - 50) &&
            newsDetails.title.length < (MAX_TITLE_COUNT - 20)
        ) {
            return "newsform__title-badge-warning";
        } else if (newsDetails.title.length >= (MAX_TITLE_COUNT - 20)) {
            return "newsform__title-badge-danger";
        }
    };

    const badgeColorDesc = () => {
        if (newsDetails.desc.length < (MAX_DESCRIPTION_COUNT - 300)) {
            return "newsform__title-badge-color";
        } else if (
            newsDetails.desc.length >= (MAX_DESCRIPTION_COUNT - 300) &&
            newsDetails.desc.length < (MAX_DESCRIPTION_COUNT - 200)
        ) {
            return "newsform__title-badge-warning";
        } else if (newsDetails.desc.length >= (MAX_DESCRIPTION_COUNT - 200)) {
            return "newsform__title-badge-danger";
        }
    };

    const handleUserType = (e) => {
        setNewsDetails((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSourceChange = (selected) => {
        setSourceState(selected);
        setNewsDetails((prev) => ({
            ...prev,
            source: selected?.value || "",
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        const errors = ["", null, undefined]

        if(errors.includes(newsDetails.title)){
            return showNotification("error", "Enter Title First")
        }

        if(errors.includes(newsDetails.source)){
            return showNotification("error", "Selecting Source Is Mandatory")
        }

        const user = getFromStorage("dnr_a")

        const payload = {
            title: newsDetails.title.trim(),
            desc: newsDetails.desc.trim(),
            source: newsDetails.source,
            link: newsDetails.link,
            publish_date: moment(newsDetails.publish_time).tz('Asia/Dhaka').startOf('day').unix(),
            publish_time: moment(newsDetails.publish_time).tz('Asia/Dhaka').unix(),
            id: id,
            update_by: user.id,
			update_at: moment().tz("Asia/Dhaka").unix()
        }

        const res = await api.updateNews(payload)

        if(res.flag === 'SUCCESS'){
            showNotification('success', res.msg + ", Redirecting Now...");
            setNewsDetails({
                title: "",
                desc: "",
                source: "",
                link: "",
            });
            
            setSourceState("");
            
            setTimeout(() => {
                navigate("/admin/news/list", { state: searchedDate })
            }, 2000);
        } else {
            showNotification('error', res.msg)
        }
    };

    return (
        <>
            <Navbar />
			<NotificationContainer />

            <h3 className="create__title">Edit News</h3>
            
            <div className="create__container">
                <form className="create__box" onSubmit={handleSubmit}>
                    <div className="create__title-wrapper">
                        <div className="create__title-head">
                            <span>News Title - </span>
                            <span
                                className={`create__title-badge ${badgeColorTitle()}`}
                            >
                                {newsDetails.title.length}/{MAX_TITLE_COUNT}
                            </span>
                        </div>
                        <input
                            className="create__title-input"
                            type="text"
                            name="title"
                            autoComplete="off"
                            maxLength={MAX_TITLE_COUNT}
                            value={newsDetails.title}
                            onChange={handleUserType}
                        />
                    </div>
                    <div className="create__desc-wrapper">
                        <div className="create__desc-head">
                            <span>News Description - </span>
                            <span
                                className={`create__title-badge ${badgeColorDesc()}`}
                            >
                                {newsDetails.desc.length}/{MAX_DESCRIPTION_COUNT}
                            </span>
                        </div>
                        <textarea
                            className="create__desc-input"
                            name="desc"
                            id=""
                            cols="30"
                            rows="5"
                            maxLength={MAX_DESCRIPTION_COUNT}
                            autoComplete="off"
                            value={newsDetails.desc}
                            onChange={handleUserType}
                        ></textarea>
                    </div>
                    <div className="newsform__source-container">
                        <span>Link: </span>
                        <input
                            className="newsform__title-input"
                            type="text"
                            name="link"
                            autoComplete="off"
                            value={newsDetails.link}
                            onChange={handleUserType}
                        />
                    </div>
                    <div className="create__source-container">
                        <div className="create__source-wrapper">
                            <span>Source: </span>
                            <Select
                                className="create__source-select"
                                maxMenuHeight={150}
                                options={sources}
                                isClearable={true}
                                isSearchable={true}
                                name="source"
                                value={sourceState}
                                onChange={(selection) =>
                                    handleSourceChange(selection)
                                }
                            />
                        </div>
                        <div className="create__publish-date">
                            <span>Date: </span>
                            <input 
                                type="datetime-local" 
                                name='publish_time'
                                value={newsDetails.publish_time}
                                onChange={handleUserType}
                            />
                        </div>
                        <div className="create__source-btns">
                            <button
                                className="create__source-btn btn-submit"
                                type="submit"
                            >
                                Update
                            </button>
                            <button
                                className="create__source-btn btn-home"
								onClick={() => navigate("/admin/news/list", { state: searchedDate })}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
