import React, { useCallback, useEffect, useState } from "react";
import moment from "moment-timezone";
import EachNews from "./news/EachNews";
import * as api from "../api/ApiRequest";
import NotificationContainer from "./utility/NotificationContainer";
import { showNotification } from "./utility/showNotification";
import { clearNotifications } from "./utility/clearNotifications";
import { useRef } from "react";
import { currentDateInUnix } from "./utility/DateTimeMethods";
import useInterval from "./utility/useInterval";
import Select from "react-select";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import debounce from "lodash.debounce"

export default function Homepage() {
    // state for, loading status
    const [isLoading, setIsLoading] = useState(true);
    // state for, date to show on the header
    const [date, setDate] = useState("");
    // state for, storing news to show
    const [news, setNews] = useState([]);
    // state for, storing news scroll
    const [scrollNews, setScrollNews] = useState("");
    // state for, storing news scroll array
    const [scrollNewsArr, setScrollNewsArr] = useState([])
    // state for, read more button states
    const [readMoreState, setReadMoreState] = useState([]);
    // state for, date wise news search
    const [searchDate, setSearchDate] = useState("");
    // state for, checked times
    const [newCheckedTimes, setNewCheckedTimes] = useState(0);
    // state for, toggle date change status
    const [dateSearchStatus, setDateSearchStatus] = useState(false);
    // state for, pagination
    const [pageNo, setPageNo] = useState(1);
    // state for, total news count for pagination button disable
    const [totalNewsCount, setTotalNewsCount] = useState(0);
    // state for, media wise filter
    const [mediaFilter, setMediaFilter] = useState(0)
    // reference to dom for focus
    const newsRef = useRef({})
    // state for, filter type
    const [filterBy, setFilterBy] = useState("")
    // state for, media list
    const [mediaList, setMediaList] = useState([])
    // state for, media search
    const [searchedMedia, setSearchedMedia] = useState('')
    // state for, is media wise searched for
    const [mediaPagination, setMediaPagination] = useState(false)
    // state for, date tooltips
    const [dateTooltipData, setDateTooltipData] = useState([])
    // state for, excluded dates in calender
    const [excludingDates, setExcludingDates] = useState([])

    // dom reference for play/pause news scroll
    const scrollRef = useRef();

    // Runs on app launch
    useEffect(
        () => {
            // checkNotificationPermission();
            fetchData();
            // checkForNewData();
        },
        // eslint-disable-next-line
        []
    );

    // check notification permission
    const checkNotificationPermission = () => {
        Notification.requestPermission().then((permission) => {
            if (permission !== "granted") {
                // alert user about notification feature
                clearNotifications();
                showNotification(
                    "info",
                    "You need to enable notification to get notified!"
                );
            }
        });
    }

    // fetching initial data on page load
    const fetchData = async (searchedPage = null) => {
        setIsLoading(true)
        // setting todays date as formatted
        setDate(moment().tz("Asia/Dhaka").format("DD MMM YYYY"))
        
        // fetch news from database
        const res = await api.fetchNewsByPage(searchedPage || pageNo)
        setNews(res?.news || [])

        // set scroll bar news
        const ticker = await api.fetchLatestNewsScroll()
        setScrollNews(ticker.scroll);
        setScrollNewsArr(ticker.arr)

        // checking latest news count
        const news = await api.checkNewNews()
        setTotalNewsCount(news?.news_count || 1)

        const data = {
            // new news count
            nnc: news?.news_count || 0,
            // timestamp form last updated at
            u: currentDateInUnix(),
            // news id already fetched
            i: [...new Set(res?.news?.map(each => each.id))]
        }

        // setting news count to local storage
        localStorage.setItem("dnr", JSON.stringify(data))

        const mediaRes = await api.fetchMediaListForUser()
        // const mediaRes = await api.fetchMediaNames()
        
        const medias = mediaRes?.list?.map(each => {
            return {
                value: each.id,
                label: each.media_name
            }
        })

        setMediaList(medias)
        setIsLoading(false)

        const dateWiseNews = await api.fetchYearlyDateWiseNewsCount()
        const excludingDateList = dateWiseNews.list
                            .filter(each => each.count === 0)
                            .map(each => new Date(each.date))
        setExcludingDates(excludingDateList)

        let allDates = {}
        dateWiseNews.list
        .filter(each => each.count !== 0)
        .forEach(each => {
            allDates[each.date] = each.count
        })
        setDateTooltipData(allDates)
    };

    const fetchNews = async (news_count) => {
        // fetching news data
        const newsRes = await api.fetchNews();
        // fetch news from database
        const res = await api.fetchNewsByPage(1)
        setNews(res?.news || [])

        setTotalNewsCount(news_count)

        const data = {
            // new news count
            nnc: news_count || 0,
            // timestamp for last updated at
            u: currentDateInUnix(),
            // already fetched news ids
            i: newsRes?.news?.map((each) => each.id),
        };

        // set scroll bar news
        const ticker = await api.fetchNewsScroll(currentDateInUnix())
        setScrollNews(ticker?.scroll || "");

        // setting news count to local storage
        localStorage.setItem("dnr", JSON.stringify(data));

        return res;
    };

    // Check for new news on every mentioned interval
    // Per minute to milliseconds (1 min = 60000 ms)
    const PER_MIN_TO_MILLISECOND = 60000;
    const MINUTE = 2;
    const WAIT_UNTIL = PER_MIN_TO_MILLISECOND * MINUTE;

    useInterval(async () => {
        console.log('checking');
        // checking news count
        const news = await api.checkNewNews();
        // geting data from local storage
        let currentCount = JSON.parse(localStorage.getItem("dnr"));
        // console.log("Checking...", currentCount.nnc, news.news_count);

        // check if storage data is last fetched today or not
        // if (
        //     currentCount?.u !==
        //     currentDateInUnix()
        // ) {
        //     // initializing new news count as 0
        //     const data = {
        //         nnc: 0,
        //         u: currentDateInUnix(),
        //         i: [],
        //     };
        //     localStorage.setItem("dnr", JSON.stringify(data));
        //     currentCount = data;
        // }

        if(dateSearchStatus) return

        // on main homepage check for new news
        if(pageNo === 1){
            // if new news post
            if (parseInt(news?.news_count) >= parseInt(currentCount?.nnc)) {
                // if new posted news id is not notified yet
                if (
                    parseInt(news?.news_count) > 0 &&
                    !currentCount?.i?.includes(news.last_news_id)
                ) {
                    const res = await fetchNews(news?.news_count);
                    setNews(res?.news || []);

                    // set scroll bar news
                    const ticker = await api.fetchNewsScroll(currentDateInUnix())
                    setScrollNews(ticker?.scroll || "");

                    // check notification permission
                    // checkNotificationPermission()

                    if (res?.flag === "SUCCESS") {
                        // latest breaking news
                        const data = res?.news?.find(
                            (each) => each.id === news.last_news_id
                        );

                        // if(data !== undefined){
                        //     const title = data.news_title;
                        //     const body = data.news_details;
                        //     const icon = "images/dnr-logo.jpg";
    
                        //     // launch desktop notification
                        //     const notification = new Notification(title, {
                        //         body,
                        //         icon,
                        //     });
    
                        //     // on notification click close & open the main news
                        //     notification.onclick = () => {
                        //         notification.close();
                        //         window.parent.focus();
                        //     };
                        // }
                    }
                }
            }
            // if database news count reduced
            else if (parseInt(news.news_count) < parseInt(currentCount?.nnc)) {
                fetchNews(news?.news_count);
            }
        } 
        // check if any news is not notified yet to new notification
        else if(!currentCount?.i?.includes(news?.last_news_id)){
            const res = await api.fetchNewsListById(news?.last_news_id)

            const title = res?.news[0]?.news_title;
            const body = res?.news[0]?.news_details;
            const icon = "images/dnr-logo.jpg";

            // launch desktop notification
            // const notification = new Notification(title, {
            //     body,
            //     icon,
            // });

            // // on notification click close & open the main news
            // notification.onclick = () => {
            //     notification.close();
            //     window.parent.focus();
            // };

            const currentCount = JSON.parse(localStorage.getItem("dnr"))
            currentCount?.i?.push(news?.last_news_id)

            localStorage.setItem("dnr", JSON.stringify(currentCount))
        }

        setNewCheckedTimes((prev) => prev + 1);

    }, WAIT_UNTIL);

    useEffect(() => {
        return () => {
            // Clean up interval when component unmounts
            clearInterval(WAIT_UNTIL);
        };
    }, []);

    // handle read more button click
    const handleReadMoreState = (index) => {
        const state = [...readMoreState];
        state.forEach((_, eachIndex) => {
            // closing all other news card read more without the pressed one
            if (eachIndex !== index) {
                state[eachIndex] = false;
            }
        });

        // toggling read more or less button
        state[index] = !state[index];
        // setting the updated state
        setReadMoreState(state);
    };

    // jump to specific date's news
    const handleDateSelection = async (value) => {
        setPageNo(1)
        
        // on clear button press
        if (value === "") {
            // change the ui date field to selected date
            setDate(moment().tz("Asia/Dhaka").format("DD MMM YYYY"));

            // set state for input
            setSearchDate("");
            // selected date to timestamp for api call
            const searchedDate = currentDateInUnix();

            // fetch news from database
            const res = await api.fetchNewsByPage(1)
            setNews(res?.news || [])

            // checking latest news count
            const news = await api.checkNewNews()
            setTotalNewsCount(news?.news_count || 1)
            
            // set scroll bar news
            const ticker = await api.fetchNewsScroll(searchedDate)
            setScrollNews(ticker.scroll);

            // proceed only if any the api call is success
            if (res?.flag === "SUCCESS") {
                res?.news?.forEach((each) => {
                    // setting read more state on basis of total news title/ new news
                    // setting initially false to toggle Read More or Less
                    setReadMoreState((prev) => [...prev, false]);
                });
            }
        } else {
            // change the ui date field to selected date
            setDate(
                moment(value, "YYYY-MM-DD")
                    .tz("Asia/Dhaka")
                    .format("DD MMM YYYY")
            );

            // set state for input
            setSearchDate(value);
            // selected date to timestamp for api call
            const searchedDate = moment(value, "YYYY-MM-DD")
                .tz("Asia/Dhaka")
                .startOf("day")
                .unix();

            const payload = {
                date: searchedDate,
                page: 1
            }
                
            const res = await api.searchNewsByDate(payload);
            setNews(res?.news || []);
            setTotalNewsCount(res?.news_count || 1)
            
            // set scroll bar news
            const ticker = await api.fetchNewsScroll(searchedDate);
            setScrollNews(ticker.scroll);

            // proceed only if any the api call is success
            if (res?.flag === "SUCCESS") {
                res?.news?.forEach(_ => {
                    // setting read more state on basis of total news title/ new news
                    // setting initially false to toggle Read More or Less
                    setReadMoreState((prev) => [...prev, false]);
                });
            }
        }

        if (
            value === "" ||
            moment(value, "YYYY-MM-DD")
                .tz("Asia/Dhaka")
                .startOf("day")
                .unix() === currentDateInUnix()
        ) {
            setDateSearchStatus(false);
        } else {
            setDateSearchStatus(true);
        }
    };

    const renderDayContents = (_, date) => {
        const searchingDate = moment(date).format('YYYY-MM-DD')
        let tooltipText = ''
        if(dateTooltipData[searchingDate]){
            tooltipText = `Total News: ${dateTooltipData[searchingDate]}`;
        }
        return <span title={tooltipText}
        >
            {moment(date).format('D')}
        </span>;
    };

    // const handleDateSelection = async (e) => {
    //     setPageNo(1)
        
    //     // on clear button press
    //     if (e.target.value === "") {
    //         // change the ui date field to selected date
    //         setDate(moment().tz("Asia/Dhaka").format("DD MMM YYYY"));

    //         // set state for input
    //         setSearchDate("");
    //         // selected date to timestamp for api call
    //         const searchedDate = currentDateInUnix();

    //         // fetch news from database
    //         const res = await api.fetchNewsByPage(1)
    //         setNews(res?.news || [])

    //         // checking latest news count
    //         const news = await api.checkNewNews()
    //         setTotalNewsCount(news?.news_count || 1)
            
    //         // set scroll bar news
    //         const ticker = await api.fetchNewsScroll(searchedDate)
    //         setScrollNews(ticker.scroll);

    //         // proceed only if any the api call is success
    //         if (res?.flag === "SUCCESS") {
    //             res?.news?.forEach((each) => {
    //                 // setting read more state on basis of total news title/ new news
    //                 // setting initially false to toggle Read More or Less
    //                 setReadMoreState((prev) => [...prev, false]);
    //             });
    //         }
    //     } else {
    //         // change the ui date field to selected date
    //         setDate(
    //             moment(e.target.value, "YYYY-MM-DD")
    //                 .tz("Asia/Dhaka")
    //                 .format("DD MMM YYYY")
    //         );

    //         // set state for input
    //         setSearchDate(e.target.value);
    //         // selected date to timestamp for api call
    //         const searchedDate = moment(e.target.value, "YYYY-MM-DD")
    //             .tz("Asia/Dhaka")
    //             .startOf("day")
    //             .unix();

    //         const payload = {
    //             date: searchedDate,
    //             page: 1
    //         }
                
    //         const res = await api.searchNewsByDate(payload);
    //         setNews(res?.news || []);
    //         setTotalNewsCount(res?.news_count || 1)
            
    //         // set scroll bar news
    //         const ticker = await api.fetchNewsScroll(searchedDate);
    //         setScrollNews(ticker.scroll);

    //         // proceed only if any the api call is success
    //         if (res?.flag === "SUCCESS") {
    //             res?.news?.forEach(_ => {
    //                 // setting read more state on basis of total news title/ new news
    //                 // setting initially false to toggle Read More or Less
    //                 setReadMoreState((prev) => [...prev, false]);
    //             });
    //         }
    //     }

    //     if (
    //         e.target.value === "" ||
    //         moment(e.target.value, "YYYY-MM-DD")
    //             .tz("Asia/Dhaka")
    //             .startOf("day")
    //             .unix() === currentDateInUnix()
    //     ) {
    //         setDateSearchStatus(false);
    //     } else {
    //         setDateSearchStatus(true);
    //     }
    // };

    const handleReturnToHomepage = () => {
        setPageNo(1);
        setDateSearchStatus(false);
        setSearchDate("");
        fetchData(1);
    };

    const handlePrevPage = async () => {
        if(mediaPagination){
            const payload = {
                page: pageNo - 1,
                media: searchedMedia?.value || 0
            }

            const res = await api.searchNewsByMedia(payload)
            setNews(res?.news || [])
        } else if(dateSearchStatus){
            const searchedDate = moment(searchDate, "YYYY-MM-DD")
                                    .tz("Asia/Dhaka")
                                    .startOf("day")
                                    .unix();

            const payload = {
                date: searchedDate,
                page: pageNo - 1
            }
                
            const res = await api.searchNewsByDate(payload);
            setNews(res?.news || []);
        } else {
            const res = await api.fetchNewsByPage(pageNo - 1)
            setNews(res?.news || [])
        }

        setPageNo((currentPage) => currentPage - 1);
        window.scrollTo(0,0)
    };

    const handleNextPage = async () => {
        if(mediaPagination){
            const payload = {
                page: pageNo + 1,
                media: searchedMedia?.value || 0
            }

            const res = await api.searchNewsByMedia(payload)
            setNews(res?.news || [])
        } else if(dateSearchStatus){
            const searchedDate = moment(searchDate, "YYYY-MM-DD")
                                    .tz("Asia/Dhaka")
                                    .startOf("day")
                                    .unix();

            const payload = {
                date: searchedDate,
                page: pageNo + 1
            }
                
            const res = await api.searchNewsByDate(payload);
            setNews(res?.news || []);
        } else {
            const res = await api.fetchNewsByPage(pageNo + 1)
            setNews(res?.news || [])
        }

        setPageNo((currentPage) => currentPage + 1);
        window.scrollTo(0,0)
    };

    const focusNews = (news_id) => {
        newsRef.current[news_id].scrollIntoView({ behavior: "smooth" })
        setTimeout(() => {
            window.scrollBy({ top: -135, behavior: 'smooth' })
        }, 800);
    }

    const handleFilter = (e) => {
        const filter = e.target.value
        setFilterBy(filter)

        if(filter === ''){
            setPageNo(1);
            setDateSearchStatus(false);
            setMediaPagination(false);
            setSearchDate("");
            setSearchedMedia("");
            fetchData(1);
        } else if(filter === 'date'){
            setPageNo(1);
            setSearchedMedia("")
            fetchData(1); 
        } else if(filter === 'media'){
            setPageNo(1);
            setDateSearchStatus(false);
            setSearchDate("");
            fetchData(1); 
        }
    }

    const handleMediaSearch = async (selection) => {
        setSearchedMedia(selection)
        
        if(selection){
            const payload = {
                page: 1,
                media: selection.value
            }

            const res = await api.searchNewsByMedia(payload)
            setNews(res?.news || [])
            setTotalNewsCount(res?.news_count || 0)
            setMediaPagination(true)
        } else {
            setPageNo(1);
            setDateSearchStatus(false);
            setSearchDate("");
            fetchData(1); 
        }
    }

    return (
        <div className="body">
            <div>
                <NotificationContainer />
                <div className="sticky-navbar">
                    <header className="header">
                        <div className="header__container">
                            <div>
                                <a href="/">
                                    <img
                                        src="images/dnr-logo-outline.png"
                                        alt="Dhaka Newsroom"
                                        className="header__logo"
                                    />
                                </a>
                            </div>
                            <div className="header__date-section">
                                <div>Dhakanewsroom.com</div>
                            </div>
                        </div>
                    </header>
                    {/* <div className="header__scroll">
                        <span className="header__scroll-text">
                            {scrollNews}
                        </span>
                    </div> */}
                    {/* eslint-disable-next-line */}
                    <marquee
                        className="header__scroll"
                        ref={scrollRef}
                        onMouseOver={() => scrollRef.current.stop()}
                        onMouseOut={() => scrollRef.current.start()}
                    >
                        {/* {scrollNews} */}
                        {scrollNewsArr?.map(each => (
                            <span
                                className="cursor"
                                onClick={() => focusNews(each.news_id)} 
                            >{each.scroll} | </span>
                        ))}
                    </marquee>
                </div>

                <div className="container">
                    <div className="container__btn-group-wrapper">
                        <div className="container__btn-group">
                            {(dateSearchStatus || pageNo !== 1) && (
                                <button
                                    className="container__btn-return"
                                    onClick={handleReturnToHomepage}
                                >
                                    Return To Homepage
                                </button>
                            )}
                            
                            <select value={filterBy} onChange={handleFilter}>
                                <option value="">Filter By: </option>
                                <option value="date">Filter By: Date</option>
                                <option value="media">Filter By: Media</option>
                            </select>
                        </div>

                        {filterBy === 'date'
                        ?
                        <div className="container__btn-group">
                            {/* <input 
                                type="date" 
                                id="date" 
                                name="date" 
                                value={searchDate}
                                onChange={handleDateSelection}
                            /> */}
                            <DatePicker 
                                dateFormat="dd-MMM-YY"
                                excludeDates={excludingDates}
                                selected={searchDate}
                                onChange={handleDateSelection}
                                renderDayContents={renderDayContents}
                            />
                        </div>
                        : '' }

                        {filterBy === 'media'
                        ?
                        <div className="container__btn-group container__media-search">
                            <Select
                                className="newsform__source-select"
                                maxMenuHeight={150}
                                options={mediaList}
                                isClearable={true}
                                isSearchable={true}
                                name="source"
                                value={searchedMedia}
                                onChange={handleMediaSearch}
                            />
                        </div>
                        : '' }
                    </div>

                    {/* <div className="container__filter">
                        <button
                            className={`container__btn-filter ${mediaFilter === 0  ? 'container__btn-filter-active' : ''}`}
                            onClick={() => setMediaFilter(0)}
                        >
                            All Media
                        </button>

                        <button
                            className={`container__btn-filter ${mediaFilter === 1  ? 'container__btn-filter-active' : ''}`}
                            onClick={() => setMediaFilter(1)}
                        >
                            Local Media
                        </button>

                        <button
                            className={`container__btn-filter ${mediaFilter === 2  ? 'container__btn-filter-active' : ''}`}
                            onClick={() => setMediaFilter(2)}
                        >
                            Foreign Media
                        </button>
                    </div> */}

                    <main className="container__main">
                        {isLoading ? (
                            <div className="news__card-blank">Loading...</div>
                        ) : news.length === 0 ? (
                            <div className="news__card-blank">
                                No Breaking News
                            </div>
                        ) : (
                            news
                            ?.filter(each => {
                                if(mediaFilter > 0){
                                    return mediaFilter === each.media_type
                                } else {
                                    return each
                                }
                            })
                            ?.map((each, index) => (
                                <EachNews
                                    key={each.news_title + index}
                                    ref={(el) => newsRef.current[each.id] = el}
                                    eachNews={each}
                                    index={index}
                                    readMoreState={readMoreState}
                                    handleReadMoreState={handleReadMoreState}
                                    newCheckedTimes={newCheckedTimes}
                                />
                            ))
                        )}
                    </main>
                    <div className="news__card-pagination">
                        <button 
                            className="news__card-btn-pagination"
                            disabled={pageNo === 1}
                            onClick={handlePrevPage}
                        >
                            Prev
                        </button>
                        {pageNo}
                        <button 
                            className="news__card-btn-pagination"
                            disabled={
                                pageNo === 
                                Math.ceil(totalNewsCount/10)
                            } 
                            onClick={handleNextPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>

            <div className="footer">
                <div>প্রিয় পাঠক।</div>
                <div>
                    ঢাকা নিউজরুম দেশ-বিদেশের সংবাদ মাধ্যমে প্রকাশিত খবর থেকে
                    শুধুমাত্র ব্রেকিং নিউজসমূহ পরিবেশন করে থাকে। বিভিন্ন মাধ্যম
                    থেকে সন্নিবেশিত খবরসমূহ পাঠকদের জন্য পরিবেশিত হয়েছে বলে এর
                    কোন দায় দায়িত্ব আমাদের উপর বর্তায় না। আপনার মতামত বা যেকোন
                    প্রয়োজনে যোগাযোগ করুন dhakanewsroom@gmail.com.
                </div>
                <div>আমাদের সাথে থাকার জন্য ধন্যবাদ।</div>
                {/* <div>
                    <span>পুরোনো সংখ্যা </span>
                    <input
                        type="date"
                        name="date"
                        id="date"
                        value={searchDate}
                        onChange={handleDateSelection}
                    />
                </div> */}
            </div>
        </div>
    );
}
